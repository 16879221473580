import { gql } from '@apollo/client';

export const USER_PROPOSALS = gql`
  query ProposalsListGet($renovationPlanId: ID!) {
    proposals(renovationPlanId: $renovationPlanId) {
      id
      name
      status
      organizationId
      type
      publishedProjectType
      createdAt
      publishedAt
      expiresAt
      acceptedAt
      requestedAt
      updatedAt
      renovationPlan {
        id
      }
      pricingEngines {
        id
        description
        name
        niceToHaveDescription
        roomType
        userMedia(mediaTypes: [IMAGE, VIDEO, DOCUMENT]) {
          id
          fileName
          fileUrl
          mediaType
        }
        selectedLook {
          look {
            id
            name
            images {
              file {
                url
              }
            }
            collection {
              name
              tags {
                tag {
                  displayName
                }
              }
            }
            properties {
              id
              label
            }
          }
        }
        selectedAnswers {
          id
          label
          identifier
          propertyGroupId
        }
      }
      latestDesignQaEntry {
        id
        status
        closingStatus
      }
      latestProcurementQaEntry {
        id
        status
        closingStatus
      }
      laborVersion
    }
  }
`;

export const PROPOSAL_DELETE = gql`
  mutation ProposalRemove($input: ProposalRemoveInput!) {
    proposalRemove(input: $input) {
      id
    }
  }
`;

export const SET_PROJECT_PROPOSALS_STATUS = gql`
  mutation SetProjectContractorChoiceProposalsReady(
    $input: SetProjectContractorChoiceProposalsReadyInput!
  ) {
    setProjectContractorChoiceProposalsReady(input: $input)
  }
`;

export const GET_PROPOSALS_STATUS = gql`
  query GetContractorChoiceProposalsStatus($id: ID!) {
    project(id: $id) {
      id
      contractorChoiceProposalsReady
    }
  }
`;
