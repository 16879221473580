import { useCallback, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik';

import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';

import { ProjectType } from 'common/apollo/enums/ProjectType';
import { PresentationType } from 'common/apollo/enums/PresentationType';

import { GetRenovationPlanOnboardingPlannerRequest } from 'ProjectProfile/common/requests/getRenovationPlanOnboardingPlannerRequest';
import { GetRenovationPlanOnboardingPlannerResponse } from 'ProjectProfile/common/responses/getRenovationPlanOnboardingPlannerResponse';
import { GET_RENOVATION_PLAN_ONBOARDING_PLANNER } from 'ProjectProfile/common/apollo/gql/getRenovationPlanOnboardingPlanner';

import { GetProjectTypeRequest } from 'common/apollo/requests/getProjectTypeRequest';
import { GetProjectTypeResponse } from 'common/apollo/responses/getProjectTypeResponse';

import { GET_PROJECT_TYPE } from 'common/apollo/queries/getProjectType.graphql';

import {
  FormValues,
  RoomTypeOptions,
} from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';
import { getInitialValues } from 'ProjectProfile/ProjectInformation/v2/projectInformation.utils';
import { UpdateProjectBuildingInfoQuestionsResponse } from 'common/apollo/responses/updateProjectBuildingInfoQuestionsResponse';
import { UpdateProjectBuildingInfoQuestionsRequest } from 'common/apollo/requests/updateProjectBuildingInfoQuestionsRequest';
import { ProjectTypeUpdateResponse } from 'common/apollo/responses/projectTypeUpdateResponse';
import { ProjectTypeUpdateRequest } from 'common/apollo/requests/projectTypeUpdateRequest';
import { PROJECT_TYPE_UPDATE } from 'common/apollo/mutations/projectTypeUpdate.graphql';
import { UPDATE_PROJECT_OVERVIEW_QUESTIONS } from 'common/apollo/mutations/updatePropertyProject';
import {
  openQuestionTypes,
  questionsMap,
} from 'common/shared/Question/question.utils';

import {
  normalizeOpenQuestionsSelection,
  normalizeQuestionsSelection,
} from 'Project/ProjectOverview/common/utils/normalizeQuestionsSelection';
import { useAlert } from 'common/hooks/useAlert';
import { useProposalsFetch } from 'Proposals/proposals.hooks';
import { ProposalType } from 'common/apollo/enums/ProposalType';
import { USER_PROPOSALS } from 'Proposals/proposals.graphql';
import { usePricingEngineUpdate } from 'ProjectProfile/common/hooks/usePricingEngineUpdate';
import { GET_PROJECT_REFERRER } from 'ProjectProfile/common/apollo/gql/getProjectReferrer';
import { GetProjectReferrerRequest } from 'ProjectProfile/common/requests/getProjectReferrerRequest';
import { GetProjectReferrerResponse } from 'ProjectProfile/common/responses/getProjectReferrerResponse';

import { USER_REFERRAL_CAMPAIGN_UPDATE } from 'common/apollo/mutations/userReferralCampaignUpdate';
import { UserReferralCampaignUpdateRequest } from 'common/apollo/requests/userReferralCampaignUpdateRequest';
import { UserReferralCampaignUpdateResponse } from 'common/apollo/responses/userReferralCampaignUpdateResponse';
import { GET_REFERRAL_CAMPAIGNS } from 'common/apollo/queries/getReferralCampaigns';
import { GetReferralCampaignsResponse } from 'common/apollo/responses/getReferralCampaignsResponse';
import { PropertyGroupIdentifier } from 'common/apollo/enums/PropertyGroupIdentifier';
import { MEDIA_MIME_TYPES } from 'common/consts/mimeTypes';
import { RoomType } from 'common/apollo/enums/RoomType';
import { PropertyIdentifier } from 'common/apollo/enums/PropertyIdentifier';

export const DESCRIPTION_MIN_CHARS = 20;
const DESCRIPTION_ERROR = `Please add more details about your room. A more detailed description leads to better contractor matches. (${DESCRIPTION_MIN_CHARS} character minimum)`;

const NUMBER_IDENTIFIERS = [
  PropertyGroupIdentifier.PROJECT_BUDGET_MAX,
  PropertyGroupIdentifier.PROJECT_BUDGET_MIN,
];

const HIDDEN_PROPERTY_GROUPS = [PropertyGroupIdentifier.OTHER_ROOM_SPACE];

export const useProjectInformation = (isInternalNotes: boolean) => {
  const acceptedFileTypes = [
    ...MEDIA_MIME_TYPES,
    'image/heic',
    'application/pdf',
  ];

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { projectId } = useParams<{ projectId: string }>();
  const { isLoading: isProposalsLoading, proposals } =
    useProposalsFetch(projectId);

  const { handleSubmit: handlePricingEngineSubmit } =
    usePricingEngineUpdate(acceptedFileTypes);

  const { onCompleted, onError } = useAlert();

  const latestProposal = useMemo(() => {
    const proposal = proposals
      ?.sort((a, b) => {
        if (a.type === ProposalType.LOOKS && b.type !== ProposalType.LOOKS) {
          return -1;
        }
        if (a.type !== ProposalType.LOOKS && b.type === ProposalType.LOOKS) {
          return 1;
        }
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
      .find(
        (p) => p.type === ProposalType.LOOKS || p.type === ProposalType.SALES,
      );

    if (!proposal) {
      return undefined;
    }

    const sortedPricingEngines = [...proposal.pricingEngines].sort(
      (a, b) => Number(a.id) - Number(b.id),
    );

    return {
      ...proposal,
      pricingEngines: sortedPricingEngines,
    };
  }, [proposals]);

  const [updateProjectInformation] = useMutation<
    UpdateProjectBuildingInfoQuestionsResponse,
    UpdateProjectBuildingInfoQuestionsRequest
  >(UPDATE_PROJECT_OVERVIEW_QUESTIONS, {
    awaitRefetchQueries: true,
    onError: (error: ApolloError) => {
      onError(error.toString());
    },
    refetchQueries: [
      {
        query: GET_RENOVATION_PLAN_ONBOARDING_PLANNER,
        variables: {
          renovationPlanId: projectId,
        },
      },
      {
        query: USER_PROPOSALS,
        variables: {
          renovationPlanId: projectId,
        },
      },
    ],
  });

  const [userReferralCampaignUpdate] = useMutation<
    UserReferralCampaignUpdateResponse,
    UserReferralCampaignUpdateRequest
  >(USER_REFERRAL_CAMPAIGN_UPDATE);

  const [projectTypeUpdate] = useMutation<
    ProjectTypeUpdateResponse,
    ProjectTypeUpdateRequest
  >(PROJECT_TYPE_UPDATE, {
    onError: (error: ApolloError) => onError(error.message),
  });

  const { data: referralCampaignsData, loading: referralCampaignsLoading } =
    useQuery<GetReferralCampaignsResponse>(GET_REFERRAL_CAMPAIGNS);

  const { data, loading: isLoading } = useQuery<
    GetRenovationPlanOnboardingPlannerResponse,
    GetRenovationPlanOnboardingPlannerRequest
  >(GET_RENOVATION_PLAN_ONBOARDING_PLANNER, {
    variables: {
      renovationPlanId: projectId,
    },
  });

  const { data: dataProject } = useQuery<
    GetProjectTypeResponse,
    GetProjectTypeRequest
  >(GET_PROJECT_TYPE, {
    skip: !Number(projectId),
    variables: { id: projectId! },
  });

  const { data: dataProjectReferrer } = useQuery<
    GetProjectReferrerResponse,
    GetProjectReferrerRequest
  >(GET_PROJECT_REFERRER, {
    variables: {
      id: projectId,
    },
  });

  const allQuestions = useMemo(() => {
    let rtrn =
      data?.onboardingPlanner?.sections
        .flatMap(
          (section) =>
            section.questions.map((q) => {
              return {
                ...q,
                // force radio types to dropdown for better usability in admin
                presentationType:
                  q.presentationType === PresentationType.RADIO
                    ? PresentationType.DROPDOWN
                    : q.presentationType,
                showContractor: !!section.showContractor,
                showHomeowner: !!section.showHomeowner,
              };
            }),
          // only show questions we have components for
        )
        .filter((q) => !!questionsMap[q.presentationType]) || [];

    if (isInternalNotes) {
      rtrn = rtrn.filter((q) => !q.showHomeowner);
    }

    return rtrn;
  }, [data?.onboardingPlanner?.sections, isInternalNotes]);

  const questions = useMemo(
    () =>
      allQuestions.filter((q) =>
        q?.identifier ? !HIDDEN_PROPERTY_GROUPS.includes(q.identifier) : true,
      ),
    [allQuestions],
  );

  const otherRoomTypeQuestion = useMemo(
    () =>
      allQuestions.find((q) => {
        return q.identifier === PropertyGroupIdentifier.OTHER_ROOM_SPACE;
      }),
    [allQuestions],
  );

  const numberQuestionIds = useMemo(() => {
    if (!questions) {
      return [];
    }

    return questions
      .filter((q) => q.identifier && NUMBER_IDENTIFIERS.includes(q.identifier))
      .map((q) => q.id);
  }, [questions]);

  const initialValues = useMemo(
    () =>
      getInitialValues(
        latestProposal?.pricingEngines || [],
        allQuestions,
        dataProject?.project.projectType,
        dataProjectReferrer?.project.user?.referrer,
      ),
    [latestProposal, allQuestions, dataProject, dataProjectReferrer],
  );

  const validations = useCallback(
    (values: FormValues) => {
      let hasError = false;
      const errors: Record<string, string> = {};

      if (values.questions) {
        numberQuestionIds.forEach((numQuestionId) => {
          if (
            values.questions[numQuestionId] &&
            !/^\d+$/.test(String(values.questions[numQuestionId]))
          ) {
            hasError = true;
            errors[`questions.${numQuestionId}`] = 'Must be a number';
          }
        });

        const projectMinQuestion = questions.find(
          (q) => q.identifier === PropertyGroupIdentifier.PROJECT_BUDGET_MIN,
        );
        const projectMaxQuestion = questions.find(
          (q) => q.identifier === PropertyGroupIdentifier.PROJECT_BUDGET_MAX,
        );

        if (projectMinQuestion && projectMaxQuestion) {
          const projectMin = Number(values.questions[projectMinQuestion.id]);
          const projectMax = Number(values.questions[projectMaxQuestion.id]);

          if (
            !Number.isNaN(projectMin) &&
            !Number.isNaN(projectMax) &&
            projectMax <= projectMin
          ) {
            errors[`questions.${projectMaxQuestion.id}`] =
              'The max homeowner budget is less than the min range of the budget calculator';
            hasError = true;
          }
        }

        const prioritiesQuestion = questions.find((q) =>
          q.answers.some(
            (a) => a.identifier === PropertyIdentifier.PRIORITY_SPEED,
          ),
        );

        const timelineQuestion = questions.find((q) =>
          q.answers.find(
            (a) => a.identifier === PropertyIdentifier.SPEED_FLEXIBILITY_HIGH,
          ),
        );

        const qualityQuestion = questions.find((q) =>
          q.answers.find(
            (a) => a.identifier === PropertyIdentifier.QUALITY_FLEXIBILITY_HIGH,
          ),
        );

        if (prioritiesQuestion && timelineQuestion && qualityQuestion) {
          const [priorityId] = values.questions[
            prioritiesQuestion.id
          ] as string[];

          if (
            priorityId ===
              prioritiesQuestion.answers.find(
                (a) => a.identifier === PropertyIdentifier.PRIORITY_SPEED,
              )?.id &&
            !values.questions[timelineQuestion.id]
          ) {
            errors[`questions.${timelineQuestion.id}`] =
              'This is required when speed is the top priority';
            hasError = true;
          } else if (
            priorityId ===
              prioritiesQuestion.answers.find(
                (a) => a.identifier === PropertyIdentifier.PRIORITY_QUALITY,
              )?.id &&
            !values.questions[qualityQuestion.id]
          ) {
            errors[`questions.${qualityQuestion.id}`] =
              'This is required when quality is the top priority';
            hasError = true;
          }
        }
      }

      if (values.rooms) {
        values.rooms.forEach((r, index) => {
          if (r.description?.length < DESCRIPTION_MIN_CHARS) {
            hasError = true;
            const descriptionKey = `rooms[${index}].description`;
            errors[descriptionKey] = DESCRIPTION_ERROR;
          }
        });
      }

      if (hasError) {
        return errors;
      }
    },
    [numberQuestionIds, questions],
  );

  const handleSubmit = async (
    values: FormValues,
    { resetForm, validateForm }: FormikHelpers<FormValues>,
  ) => {
    const openQuestionIds = questions
      ?.filter((question) =>
        openQuestionTypes.includes(question.presentationType),
      )
      .map((question) => question.id);

    const answers = normalizeQuestionsSelection(
      values.questions,
      openQuestionIds,
    );
    const openAnswers = normalizeOpenQuestionsSelection(
      values.questions,
      openQuestionIds,
    );

    const roomsData = values.rooms.map((room) => {
      const photosToUpload = room.photos
        .filter((photo) => !!photo.file)
        .map(({ file }) => file!);

      const initialRoom = initialValues.rooms.find((pe) => pe.id === room.id);

      const photosToRemove = initialRoom!.photos.filter((initialPhoto) =>
        room.photos.every((photo) => initialPhoto.id !== photo.id),
      );

      const removePhotos =
        photosToRemove.length > 0
          ? { removePhotos: photosToRemove.map((photo) => photo.id) }
          : {};

      const uploadPhotos =
        photosToUpload.length > 0 ? { uploadPhotos: photosToUpload } : {};

      return {
        input: {
          ...removePhotos,
          ...uploadPhotos,
          description: room.description,
          name: room.name,
          pricingEngineId: room.id,
        },
      };
    });

    setIsSaving(true);

    await updateProjectInformation({
      variables: {
        input: {
          answers,
          openAnswers,
          projectId,
        },
      },
    });

    const hasProjectTypeChanged =
      values.projectType !== dataProject?.project.projectType;

    if (hasProjectTypeChanged) {
      await projectTypeUpdate({
        variables: {
          input: {
            projectId,
            projectType: values.projectType as ProjectType,
          },
        },
      });
    }

    const hasReferralCampaignChanged =
      (values.campaignId &&
        values.campaignId !==
          dataProjectReferrer?.project.user?.referrer?.campaignId) ||
      (values.referrerId &&
        dataProjectReferrer?.project.user?.referrer?.id !== values.referrerId);

    if (hasReferralCampaignChanged) {
      await userReferralCampaignUpdate({
        variables: {
          input: {
            campaignCodePrefix: values.campaignCodePrefix,
            campaignId: values.campaignId,
            projectId,
            referrerId: values.referrerId,
          },
        },
      });
    }

    await roomsData.reduce(async (lastPromise, roomData) => {
      await lastPromise;
      await handlePricingEngineSubmit({ request: roomData });
    }, Promise.resolve());

    onCompleted('Saved Changes');
    resetForm({ values });
    validateForm();
    setIsSaving(false);
  };

  const referralCampaigns = referralCampaignsData?.referralCampaigns || [];

  const referralCampaignsOptions =
    referralCampaigns.map((campaign) => ({
      label: campaign.name,
      value: campaign.id,
    })) || [];

  const roomTypeOptions: RoomTypeOptions[] = useMemo(() => {
    return [
      {
        label: 'Bathroom',
        roomType: RoomType.BATHROOM,
        value: RoomType.BATHROOM,
      },
      {
        label: 'Kitchen',
        roomType: RoomType.KITCHEN,
        value: RoomType.KITCHEN,
      },
      ...(otherRoomTypeQuestion?.answers
        .map(({ id, identifier, label }) => ({
          id: id,
          label: label || '',
          roomType: RoomType.OTHER,
          value: identifier || PropertyIdentifier.SPACE_SOMETHING_ELSE,
        }))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }) || []),
    ];
  }, [otherRoomTypeQuestion]);

  return {
    handleSubmit,
    initialValues,
    isLoading:
      isLoading ||
      isProposalsLoading ||
      !latestProposal ||
      referralCampaignsLoading,
    isSaving,
    latestProposal,
    projectId,
    questions,
    referralCampaigns,
    referralCampaignsOptions,
    referrer: dataProjectReferrer?.project.user?.referrer,
    roomTypeOptions,
    validations,
  };
};
