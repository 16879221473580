import EditIcon from '@material-ui/icons/Edit';
import { Box, IconButton } from '@material-ui/core';

import { Badge } from 'common/components/Badge/Badge';

import { ProjectType } from 'common/apollo/enums/ProjectType';

import { useGetProjectType } from 'common/hooks/Project/useGetProjectType';
import {
  isNoDeposit,
  isPersonalizedMatch,
  isTailoredTOF,
  isTailoredTOFLP,
  isTailoredTOFSWAE,
} from 'common/utils/projectFlags';
import {
  BadgesContainer,
  SmallBadge,
} from 'common/shared/Sidebar/ProjectTypeTag/projectTypeTag.styles';
import { editIconStyles } from 'common/shared/Sidebar/Profile/Profile.styles';
import {
  DIALOG_ID,
  UpdateProjectFlagsDialog,
} from 'common/shared/Sidebar/UpdateProjectFlagsDialog/UpdateProjectFlagsDialog';
import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';

export const ProjectTypeBadge = ({ renovationPlanId = '' }) => {
  const { hasPlanner, isContractorChoice, loading, projectFlags, projectType } =
    useGetProjectType(renovationPlanId);

  const setDialogOpen = useSetModalOpen();

  const isDirect = projectType === ProjectType.DIRECT;
  const isBlock10 = projectType === ProjectType.CORE;
  const isTTOF = isTailoredTOF(projectFlags);
  const isPM = isPersonalizedMatch(projectFlags);
  const isInstantMatch = isTTOF && hasPlanner && !isPM;
  const isSpeakWithAnExpert =
    isTTOF && !hasPlanner && isTailoredTOFSWAE(projectFlags);
  const isInstantMatchLP =
    isTTOF && !hasPlanner && isTailoredTOFLP(projectFlags);
  const hasDeposit = !isNoDeposit(projectFlags);

  const onEdit = () => {
    setDialogOpen(DIALOG_ID, true);
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <BadgesContainer>
        <Box>
          {isDirect && <Badge variant="gray">Direct</Badge>}
          {isBlock10 && (
            <Badge data-testid="block-1.0-project-type-badge" variant="gray">
              Block 1.0
            </Badge>
          )}
          {!isContractorChoice && (
            <Badge data-testid="direct-match-project-type-badge" variant="red">
              Direct Match
            </Badge>
          )}
          {isTTOF && (
            <SmallBadge
              data-testid="tailored-TTOF-project-type-badge"
              variant="purple"
            >
              Tailored TOF {isInstantMatch && '- Instant Match'}
              {isSpeakWithAnExpert && '- Speak with an Expert'}
              {isInstantMatchLP && '- LP'}
              {isPM && '- Personalized Match'}
            </SmallBadge>
          )}
          {hasDeposit && (
            <Badge data-testid="deposit-project-type-badge" variant="yellow">
              Deposit
            </Badge>
          )}
        </Box>
        {(hasDeposit || !isContractorChoice) && (
          <IconButton
            aria-label="edit-profile"
            css={editIconStyles}
            onClick={onEdit}
          >
            <EditIcon />
          </IconButton>
        )}
      </BadgesContainer>

      <UpdateProjectFlagsDialog
        isContractorChoice={isContractorChoice || false}
        isNoDeposit={isNoDeposit(projectFlags)}
        projectId={renovationPlanId}
      />
    </>
  );
};
