import { ApolloError, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { DIALOG_ID } from 'ProjectProfile/ProjectInformation/v2/AddSpaceDialog/AddSpaceDialog';
import { AddSpaceFormValues } from 'ProjectProfile/ProjectInformation/v2/AddSpaceDialog/addSpaceDialog.types';
import { ADD_ROOMS_TO_LOOKS_PROPOSAL } from 'ProjectProfile/common/apollo/gql/addRoomToLooksProposal';
import { AddRoomsToLooksProposalRequest } from 'ProjectProfile/common/requests/addRoomsToLooksProposalRequest';
import { AddRoomsToLooksProposalResponse } from 'ProjectProfile/common/responses/addRoomsToLooksProposalResponse';

import { USER_PROPOSALS } from 'Proposals/proposals.graphql';

import { useSetModalOpen } from 'common/hooks/ModalProvider/modal.hooks';
import { useAlert } from 'common/hooks/useAlert';
import { RoomType } from 'common/apollo/enums/RoomType';
import { RoomTypeOptions } from 'ProjectProfile/ProjectInformation/v2/projectInformation.types';

export const useAddSpace = (roomTypeOptions: RoomTypeOptions[]) => {
  const setDialogOpen = useSetModalOpen();
  const { projectId } = useParams<{ projectId: string }>();
  const { onCompleted, onError } = useAlert();

  const [addRoom] = useMutation<
    AddRoomsToLooksProposalResponse,
    AddRoomsToLooksProposalRequest
  >(ADD_ROOMS_TO_LOOKS_PROPOSAL, {
    awaitRefetchQueries: true,
    onCompleted: () => onCompleted('Room Added'),
    onError: (error: ApolloError) => {
      onError(error.toString());
    },
    refetchQueries: [
      {
        query: USER_PROPOSALS,
        variables: {
          renovationPlanId: projectId,
        },
      },
    ],
  });

  const onAddRoomClick = () => {
    setDialogOpen(DIALOG_ID, true);
  };

  const addSpace = (values: AddSpaceFormValues) => {
    const option = roomTypeOptions.find((o) => {
      return o.value === values.type;
    });

    if (!option) return;

    let otherRoomSpaceTypePropertyId = undefined;
    if (option.roomType === RoomType.OTHER) {
      otherRoomSpaceTypePropertyId = option.id;
    }

    addRoom({
      variables: {
        input: {
          renovationPlanId: projectId,
          rooms: [
            {
              name: values.name,
              otherRoomSpaceTypePropertyId,
              type: option.roomType,
            },
          ],
        },
      },
    }).then(() => {
      setDialogOpen(DIALOG_ID, false);
    });
  };

  const cancelAddSpace = () => {
    setDialogOpen(DIALOG_ID, false);
  };

  return {
    addSpace,
    cancelAddSpace,
    onAddRoomClick,
    roomTypeOptions,
  };
};
